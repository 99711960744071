import React from "react"
import { graphql, PageProps } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import HeroSection from "@components/LoloBrand/HeroSection"
import ConnectSection from "@components/LoloBrand/ConnectSection"
import ValuesSection from "@components/LoloBrand/ValuesSection"
import BottomSection from "@components/LoloBrand/BottomSection"
import { LoloBrandDataType } from "@typings/requests/partners/lolo-brand"

const LoloBrandPage: React.FC<PageProps<LoloBrandDataType>> = props => {
  const {
    allStrapiLoloBrandPage: {
      nodes: [loloBrandData],
    },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
  } = props.data
  const { heroSection, connectSection, valuesSection, bottomSection } =
    loloBrandData

  return (
    <>
      <SEO
        title={loloBrandData.title}
        description={loloBrandData.description}
        pathname={props.location.pathname}
        image={loloBrandData.image.url}
        lang="es"
      />
      <ParallaxProvider>
        <LayoutContainer
          className="brand-page"
          location={props.location}
          lang="es"
          colorLangSwitcher="black"
          isPartnerSubdomain
        >
          <HeroSection
            {...heroSection}
            image={heroSection.image.localFile.childImageSharp.gatsbyImageData}
          />
          <ConnectSection
            {...connectSection}
            image={
              connectSection.image.localFile.childImageSharp.gatsbyImageData
            }
            description={connectSection.description.data.description}
            heroSubDescription={heroSection.heroSubDescription}
            heroSubTitle={heroSection.heroSubTitle}
          />
          <ValuesSection {...valuesSection} />
          <BottomSection {...bottomSection} footerData={footerData} />
        </LayoutContainer>
      </ParallaxProvider>
    </>
  )
}

export const query = graphql`
  {
    allStrapiLoloBrandPage(filter: { locale: { eq: "es" } }) {
      nodes {
        title
        description
        image {
          url
        }
        heroSection {
          heroDescription
          heroSlogan
          heroSubDescription
          heroSubTitle
          heroTitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 263, layout: FIXED, quality: 100)
              }
            }
          }
        }
        connectSection {
          description {
            data {
              description
            }
          }
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 574
                  quality: 100
                  layout: FIXED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
        valuesSection {
          title
          cardValue {
            description
            title
            name
            id
          }
        }
        bottomSection {
          title
          buttonLink: button {
            url
            label
          }
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`

export default LoloBrandPage
